<template>
  <router-view></router-view>
</template>

<script>
export default {

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,500;0,800;0,900;1,100;1,300;1,400;1,600;1,700&display=swap');
template {
  font-size: 16rem;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
p{
  font-size: 1rem;
}
</style>
