<template>
    <div class="terms-conditions-container">
        <div class="home-wrapper">
            <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-blue">
    <div class="container-fluid">
        <h1 class="navbar-brand">Terms and Conditions</h1>
      <!-- <a class="navbar-brand" href="/educatiion">EDUCATION</a> -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav me-auto mb-2 mb-md-0">
          <li class="nav-item">
            <a class="nav-link" href="/">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/cashchat">CashChat</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/education">Education</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/kaatchup">Kaatchup</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/about-us">About-us</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
            <div class="home-content">
                <!-- <h1>TERMS & CONDITIONS</h1> -->
            </div>
            <div class="more-home-content">
                <p>Creating avenues for change.</p>
            </div>
             <button class="contact-us-btn" @click="contactUs"> <i class="fa fa-envelope-o" aria-hidden="true"></i> Contact us</button>
    </div>
    <section class="terms-conditions">
        <h3>TERMS AND CONDITIONS</h3>
        <p>These terms and conditions apply to and govern your access to and use of the PESAMONI website and the services available on the said website.</p>
        <p>It is important that you read and understand our terms and conditions before you accept them. They contain limitations on the scope of our obligations to you, as well as limitations on and exemptions from our liability to you in the event that you suffer loss as a result of using our services.</p>
        <h5>1. General</h5>
        <p>The PESAMONI Service is offered by PesaMoni Ltd in conjunction with GSM Networks and Banking System and Stanbic Bank Uganda Limited. Stanbic Bank Uganda Limited is supervised by the Central Bank of Uganda. You must be of majority age to use the PESAMONI Service. Money transfers will be terminated into a recipient’s GSM Network Mobile Money wallet. By completing the information required to send a money transfer, by providing access to the funds to be sent as well as identification as required and by accepting the Terms and Conditions, the Sender consents to the execution of the money transfer. The Sender may inform the Receiver about the money transfer. Money transfers are available to the receiver within minutes.</p>
        <h5>Transfer fees:</h5>
        <p>Written information explaining how we charge the Sender for making a money transfer is available from the PESAMONI service website, and is shown to the Sender prior to completion of the payment order. Sender will bear all fees for the money transfer. The recipient will incur withdrawal charges when accessing the money transferred to their GSM Network Mobile Money Wallet.</p>
        <p>The Receiver's agreement with GSM NETWORK governs the account and determines their rights, liability, fees, funds availability and account limitations. In the event of an inconsistency between the mobile phone number and the name of the Receiver, the transfer will be credited to the mobile phone number provided by the Sender.</p>
        <h5>Foreign exchange:</h5>
        <p>(a) All money transfers to Receivers’ GSM NETWORK Mobile Money wallets will be terminated in Uganda Shillings. Currency is converted at the then current rate of exchange. We calculate our rate of exchange based on commercially available interbank rates plus a margin. Most rates of exchange are adjusted several times daily in line with the relevant closing rate of financial markets globally.</p>
        <p>(b) The currency will be converted at the time of transfer and the Receiver will receive the equivalent in local currency. Additional information about exchange rates for can be obtained by contacting us.</p>
        <h5>Definitions of various terms referred to in the PESAMONI Terms and Conditions:</h5>
        <div class="tabbed-paragraphs">
            <p>“Agreement” means these Terms and Conditions of Use;</p>
            <p>"Bank Card" refers to a Visa or MasterCard credit card or debit card issued by or through an authorized bank;</p>
            <p>"Card Issuer" refers to the issuer and owner of a bank card;</p>
            <p>"Prohibited Purpose" refers to any unlawful purpose; to making or receiving payments for gambling services, gambling chips or gambling credits; or to sending a payment to yourself as the Receiver in order to evidence financial soundness (RISK OF FRAUD); or sending or receiving money on behalf of someone else;</p>
            <p>"Receiver" refers to the person identified as the beneficiary of a money transfer and is registered for Mobile Money service;</p>
            <p>"Sender" refers to the person who initiates the carrying out of a money transfer by using the PESAMONI Service;</p>
            <p>"Transaction" refers to each money transfer that you initiate under, and each other use that you make of, the PESAMONI Service;</p>
            <p>"PESAMONI Service" refers to any or all of the services which are made available by us by means of the PESAMONI Site;</p>
            <p>"PESAMONI Site" or "site" refers to the Web site operated by us to provide online money transmission services and related information facilities; and "You" or "Your" refers to any person who uses the PESAMONI Site or the PESAMONI Service, whether as a Sender or as a Receiver.</p>
        </div>
        <h5>2. Our Responsibility To You</h5>
        <p>2.1. Our agreement with you is that we will take reasonable care to provide the PESAMONI Service. As such, we agree to provide you with the money transmission and the related information facilities described from time to time on the PESAMONI Site.</p>
        <p>2.2. We will generally rely without further enquiry on messages that are transmitted through the PESAMONI Site and actually received by us, unless we have actual notice that any such message is incorrect, unauthorized or forged.</p>
        <p>2.3. We do not accept any responsibility to you for:</p>
        <p>(a) The goods or services which you pay for by using the PESAMONI Service. We strongly advise that you do not use our service to pay for goods purchased from an online seller that you do not know;</p>
        <p>(b) Malfunctions in communications facilities which cannot reasonably be considered to be under our control and that may affect the accuracy or timeliness of messages you send to us;</p>
        <p>(c) Any losses or delays in transmission of messages arising out of the use of any Internet access service provider or caused by any browser or other software which is not under our control;</p>
        <p>(d) The services provided to you by your card issuer;</p>
        <p>(e) Viruses caused by third parties;</p>
        <p>(f) Errors on the Site or with the PESAMONI Service caused by incomplete or incorrect information provided to us by you or a third party;</p>
        <p>(g) Any unauthorized use or interception of any message or information before it reaches the Site; or</p>
        <p>(h) any un authorized use of or access to data relating to you or your transactions which is held by us (unless such use or access is caused by our negligence, fraud or failure to comply with laws relating to the protection of your data); and the contractual relationship between us does not extend to the benefit of the Receiver and the Receiver does not have an independent right to demand payment.</p>
        <p>2.4. We have no obligation to you to initiate or perform a money transfer or other transaction as part of the PESAMONI Service if:</p>
        <p>(a) We are unable to obtain satisfactory evidence of your identity;</p>
        <p>(b) We have reason to believe that the transaction message is incorrect, unauthorized or forged;</p>
        <p>(c) You provide us with incorrect or incomplete information or if your transaction message is not given to us sufficiently in advance to allow for timely provision of the requested transaction; or</p>
        <p>(d) Your card issuer does not authorize your use of your bank card for payment of the transaction and our related charges, and we do not accept any liability for damages resulting from non-payment or delay in payment of a money transfer to a Receiver or failure to perform a transaction under the PESAMONI Service by reason of any of these matters.</p>
        <p>2.5. We may refuse to provide the PESAMONI Service (in whole or in part) to you if to do so may breach any applicable law, order of a court or requirement of any regulatory or governmental authority, body or agency having jurisdiction over us, or if we otherwise consider such action necessary to protect our interests. However, if we refuse to provide the PESAMONI Service (in whole or in part) for any of these reasons, we will wherever practicable notify you that we have done so and, unless we are prevented from doing so for some legal reason, we will explain why we have done so.</p>
        <p>2.6. We may suspend the operation of the PESAMONI Site or the PESAMONI Service in whole or in part if, in our absolute discretion, we consider it appropriate to do so by reason of any circumstances beyond our control. We undertake that if the services provided by the PESAMONI Service are interrupted (whether by us, any third party service provider or otherwise) for any reason we will take reasonable care to minimize the duration of any interruption. Provided we comply with this undertaking, we shall not be liable to you for any loss or liability which may be suffered or incurred by you as a result of any such interruption, even if caused by our negligence, except where any such interruption is caused by our fraud.</p>
        <p>2.7. We reserve the right to change the PESAMONI Service without notice. We may refuse to provide the PESAMONI Service to any person without giving a reason, in particular to prevent fraud, money laundering or terrorist financing or to comply with any applicable law, order of a court or requirement of any regulatory or governmental authority.</p>
        <p>2.8. We will generally communicate with you through the Internet and by e-mail. This does not affect your right to communicate with us by any other means described in clauses 4.1, 5 and 7 below in the circumstances contemplated by those provisions.</p>
        <p>2.9. We may change our Terms and Conditions from time to time. Each time you send money though PESAMONI, you will be provided access to a copy of our Terms and Conditions before completing the transaction. We will notify you of any change to our Terms and Conditions by posting a clear and readily accessible notice of change on the PESAMONI Site. No change which we may make will affect the terms on which you used the PESAMONI Site, or on which we provided the PESAMONI Service to you, before we posted the notice of change on the Site.</p>
        <h5>3. Your Responsibilty To Us</h5>
        <p>3.1. You agree to pay our charges for each money transfer transaction which you initiate or request under the PESAMONI Service. Clear information explaining our charges can be viewed at the PESAMONI site, which provides the estimated cost of sending an online money transfer (inclusive of tax).</p>
        <p>3.2. You must pay for the principal amount of a money transfer and our charges by using a bank card. Each time you use the PESAMONI Service, you agree that we are authorized to charge your card issuer for the principal amount of any money transfer and our charges relating to the relevant transaction before we perform the money transfer transaction. The amount that we will charge to your card issuer or your bank account will be notified to you prior to the final authorization of the transaction.</p>
        <p>3.3. You acknowledge and agree that:</p>
        <p>(a)When you register with us, you will provide us with true, accurate, current and complete information;</p>
        <p>(b)You will maintain and promptly update such information to keep it true, accurate, current and complete;</p>
        <p>(c) You will not use the PESAMONI Service for or in connection with any prohibited purpose;</p>
        <p>(d) You are solely responsible for any transmitted material and or communication, which is classified as defamatory, illegal or in breach of any copyright and shall indemnify and keep us indemnified against any claims and expenses made against us in respect thereof.</p>
        <p>(e)you will not initiate a money transfer or other transaction under the PESAMONI Service in breach of these Terms and Conditions or any other restriction or requirement of use described on the PESAMONI Site; and</p>
        <p>(f) You are responsible for the security of your password and e-mail account log-in in accordance with clause 7 below.</p>
        <p>3.4. You acknowledge and agree that information about you, and the services we provide to you, may be provided by us from time to time to regulatory or governmental authorities, bodies or agencies where:</p>
        <p>(a) We are required by law to do so; or</p>
        <p>(b) If we determine that such disclosure may help to combat fraud, money laundering offences or other criminal activity.</p>
        <p>3.5. You agree that you will generally communicate with us through the Internet and by e-mail. This does not affect your right to communicate with us by any other means described in clauses 4.1, 5 and 7 below in the circumstances contemplated by those provisions</p>
        <h5>4. Cancellation, Refunds And Administrative Charges</h5>
        <p>4.1. Under Laws relating to online contracts, you have a right to cancel your agreement with us at any time after you indicate your acceptance of our Terms and Conditions, subject to the terms of this clause. This right of cancellation continues until the expiry of fourteen days beginning on the day after your acceptance of our Terms and Conditions. You may cancel your agreement with us by:</p>
        <p>(a) Writing to us at;</p>
        <div class="tabbed-paragraphs">
            <p>PesaMoni Uganda Ltd </p>
            <p>Velocity Building Ntinda</p>
            <p>Kampala, Uganda</p>
            <p>Tel +256-789602669, +256701014671 , +256-(0)-754187853</p>
        </div>
        <p>(b) E-mailing us at info@PesaMoni.com</p>
        <p>4.2. If you exercise your right to cancel under clause 4.1 before you have sent a money transfer order to us, your agreement with us will terminate and you will not be able use the PESAMONI Service to send any money transfer orders. We will not charge you any cancellation fee.</p>
        <p>4.3. If you exercise your right to cancel under clause 4.1 after you have sent a money transfer order to us, we will reimburse you in accordance with Clause 4.4 below, save that we will not reimburse you if we have paid the money transfer to the Receiver before we receive your notice of cancellation.</p>
        <p>4.4. Your right of cancellation under Laws relating to online contracts is in addition to your cancellation rights as Sender, or as s a money transfer Sender, you have the following rights:</p>
        <p>(a) We will refund the principal amount of a money transfer and the transfer fee upon the Sender's written request to the address above by letter or email in case;</p>
        <p>(i) The Sender cancels, no fraud is detected, the money hasn't been delivered and the Sender's credit card has not been charged; or</p>
        <p>(ii) The Sender cancels, fraud is detected, the money hasn't been delivered, and the Sender's credit card has not been charged; or</p>
        <p>(iii) The transaction is declined by PESAMONI; or</p>
        <p>(iv) The Sender wants to make a change, but PESAMONI requires the transaction to be canceled before making the change; or</p>
        <p>(v) The money transfer is not available to the Receiver within three (3) business days, subject to conditions beyond the control PESAMONI, such as regulatory requirements, inclement weather or telecommunicationsfailure.</p>
        <p>(b) PESAMONI will refund only the principal amount of a money transfer upon the Sender's written request to the address above by letter or email in case that:</p>
        <p>(i) The Receiver rejects the transfer; or</p>
        <p>(ii) The Sender cancels the transfer, no fraud is detected, and the Sender's credit card has been charged; or</p>
        <p>(iii) The Sender cancels the transfer, fraud is detected, the money hasn't been delivered, and the Sender's credit card has been charged;</p>
        <p>(iv) Payment to the Receiver is not made within forty five (45) days.</p>
        <h5>5. Customer Service</h5>
        <p>If you discover any errors or have any problems with or related to the PESAMONI Site or PESAMONI Service, you can contact us by e-mail at info@pesamoni.com</p>
        <h5>6. Registration</h5>
        <p>You will be required to register with us in order to use the PESAMONI Service. Registration is subject to these Terms and Conditions.</p>
        <h5>7. Password And Security</h5>
        <p>You will choose a password and account e-mail login upon registering with us. You are responsible for maintaining the confidentiality of your password and account e-mail login, and are fully responsible to us for all activities that occur under your password or account e-mail login. You agree to immediately notify us by calling us at Tel +256-(0)-313-673-710, +256-(0)-789-602-669 of any unauthorized use of your password or account e-mail login or any other breach of security. Once you have told us that there has been an unauthorized use of your password or account e-mail login, we will take immediate steps to try to prevent these from being used. Subject to our taking such steps, we are not liable for any loss or damage arising from your failure to comply with your obligation under clause 5 or this clause 7. You may have a right against your card issuer to have sums refunded or recredited to you if fraudulent use is made of your bank card.</p>
        <h5>8. Intellectual Property</h5>
        <p>The PESAMONI Site and the PESAMONI Service, the content, and all intellectual property relating to them and contained in them (including but not limited to copyrights, patents, database rights, trademarks and service marks) are owned by us, our affiliates, or third parties. All right, title and interest in and to the PESAMONI Site and the PESAMONI Service shall remain our property and/or the property of such other third parties. The PESAMONI Site and the PESAMONI Service may be used only for the purposes permitted by these Terms and Conditions or described on the Site. You are authorized solely to view and to retain a copy of the pages of the PESAMONI Site for your own personal use. You may not duplicate, publish, modify, create derivative works from, participate in the transfer or sale of, post on the World Wide Web, or in any way distribute or exploit the PESAMONI Site, the PESAMONI Service or any portion thereof for any public or commercial use without our express written permission.</p>
        <p>You may not:</p>
        <p>(a) use any robot, spider, scraper or other automated device to access the PESAMONI Site or PESAMONI Service; and/or</p>
        <p>(b) remove or alter any copyright, trademark or other proprietary notice or legend displayed on the PESAMONI Site (or printed pages of the Site). The name PESAMONI and other names and indicia of ownership of PesaMoni products and/or services referred to on the PESAMONI Site are our exclusive marks or the exclusive marks of other third parties. Other product, service and company names appearing on the Site may be trademarks of their respective owners.</p>
        <h5>9. Entire Agreement</h5>
        <p>These Terms and Conditions, together with all other matters incorporated into these Terms and Conditions by reference, embody the entire agreement and understanding between you and us and supersede and terminate all prior agreements or understandings you may have with us.</p>
        <h5>10. Transfer</h5>
        <p>We have the right to transfer, assign or delegate our rights and/or responsibilities in whole or in part under our agreement with you to an affiliated company, or any third party, at any time without your consent unless such transfer would reduce your rights and/or remedies or increase your responsibilities and/or liabilities under your agreement with us. You shall not transfer your rights and/or responsibilities under your agreement with us without our prior written consent.</p>
        <h5>11. Termination And Suspension</h5>
        <p>11.1. Either you or we may terminate the agreement between us upon giving not less than 1 day's notice, save that any pending transaction will continue to be governed by these terms and conditions until completion. You may send us a notice of termination by any of the means described in clause 4.1 above.</p>
        <p>11.2. You shall notify us immediately if any event occurs that affects or might affect your agreement with us.</p>
        <p>11.3. We may terminate our agreement with you and/or suspend the PESAMONI Service (in whole or in part) in relation to you with immediate effect and without prior notice to you if:</p>
        <p>(a) Insufficiency of funds;</p>
        <p>(b) You are in breach of any provision of these Terms and Conditions;</p>
        <p>(c) it appears to us that you have become or are likely to become mentally incapable of managing your property and affairs, or any other event occurs which affects your legal capacity or ability to contract with us on our Terms and Conditions; and</p>
        <p>(d) Your use of the PESAMONI Service or the Site is disruptive to our other customer</p>
        <p>We shall endeavour to give you prior notice of any such termination or suspension and, if it is not practicable to give such prior notice, endeavour to give notice to you of such termination or suspension as soon as possible thereafter.</p>
        <p>11.4. We may immediately terminate our agreement with you and/or suspend the PESAMONI Service on the basis of information supplied to us (whether orally or in writing) that we in good faith believe to be true and that affects or potentially affects the validity of a transaction.</p>
        <p>11.5. The termination of our agreement with you and/or the suspension of the PESAMONI Service shall not affect any rights or obligations arising prior to or arising during or after the date of termination or suspension or which arise in consequence of it, and all such rights and obligations shall continue to be subject to these Terms and Conditions.</p>
        <p>11.6. Neither you nor we shall be liable to the other for any liabilities suffered or incurred by the other arising out of the taking of any action which you or we are authorized to take or which is otherwise provided for under these Terms and Conditions or by any restriction or requirement for use of the PESAMONI Service described on the Site, including without limitation the exercise of any powers of termination and/or suspension.</p>
        <p>11.7. The provisions of our agreement with you shall continue to apply during any suspension of the PESAMONI Service. The provisions of clauses 1.3 to 1.6 (inclusive), 2.4, 6 to 11 (inclusive), 12 and 13 (and the definitions set out above which are used in those clauses) shall survive any termination of our agreement with you.</p>
        <h5>12. Severability</h5>
        <p>In the event any one or more of the provisions of these Terms and Conditions shall for any reason be held to be invalid, illegal or unenforceable, the remaining provisions shall remain valid and enforceable.</p>
        <h5>13. Governing Law</h5>
        <p>Our agreement with you is governed by Ugandan law. We both agree that any dispute, claim or other matter relating to the PESAMONI Site or the PESAMONI Service will be dealt with by the Ugandan courts only.</p>
        <h5>14. Data Protection</h5>
        <p>PESAMONI collects, processes, holds and uses personal data provided in the money transfer instruction, together with other information provided, collected or generated for loyalty programs, marketing promotions or other services requested during the course of your relationship with PESAMONI. PESAMONI collects Your Information for the purposes (including, without limitation, administration, customer service, user validation, fraud prevention, product and business development activities) of providing you with the requested services, you signed up for with PESAMONI. PESAMONI will hold and retain Your Information and the information you provided about other person(s) (“Third Party Information”) in order to execute the transaction. The provision of Your Information and the Third Party’s Information is compulsory to execute the transaction. Without the relevant information, PESAMONI is unable to execute the money transfer, facilitate convenience activities or other requested services. PESAMONI will use the personal information you provided during your Mobile Money Transfer Service registration in accordance with your marketing preferences.</p>
        <p>PESAMONI will disclose Your Information and Third Party Information to third parties located outside of Uganda with whom it has a contractual relationship to protect Your Information and Third Party Information such as authorized service providers, Agents and other third parties as reasonably required to carry out the money transfer or to facilitate future transactions, or to supplement Your Information with information from publicly available sources, such as information to validate the accuracy of your address. This also helps PESAMONI to understand and improve its product and service offerings. PESAMONI may also disclose Your Information and Third Party Information to third parties, where reasonably necessary, for the purposes of the prevention and detection of crime, prosecution of offenders, for purposes of national security or when required by law.</p>
        <p>PESAMONI retains Your Information, Third Party Information, your marketing preferences and transaction history based on our record retention schedules or for no longer than is necessary for the purposes for which the data were collected and in compliance with applicable Laws.</p>
        <h5>15. Acceptance And Commencement Of Conditions Of Use</h5>
        <p>You are deemed to have accepted these Conditions of Use as amended from time to time and which take effect:</p>
        <p>5.1.1 Upon Registration; or</p>
        <p>5.1.2 upon the initial activation of your "PESAMONI Service".</p>

    </section>
    <my-footer></my-footer>
    </div>
</template>

<script>
import MyFooter from './footer/MyFooter.vue'
export default {
    components:{
        MyFooter
    },
    methods:{
      contactUs(){
        this.$router.push('/contact-us')
      }
    }
}
</script>

<style scoped>
    .terms-conditions-container{
    background-color: #fff;
    overflow: hidden;
}
.navbar-dark{
    background-color: #1a84c6;
}
.home-wrapper{
        background-image: url(https://images.pexels.com/photos/4065405/pexels-photo-4065405.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
        background-size: cover;
        height: 80vh;
        color: #fff;
    }
    a{
      text-decoration: none;
      font-size: 1.3rem;
      color: #fff;
    }
    .home-content{
        position: absolute;
        top: 20rem;
        left: 5rem;
        color: #fff;
        width: 45%;
    }
    .more-home-content{
    position: absolute;
    top: 32rem;
    left: 25rem;
    background-color:#f3874f ;
    }
    .more-home-content p{
        text-align: center;
        padding: 1rem 8rem;
        font-size: 1.5rem;

    }
    .contact-us-btn{
      position: fixed;
      right: 0;
      bottom: 0;
      height: 3rem;
    }
    .contact-us-btn{
          margin: 3rem;
          padding: 10px 30px;
          border: none;
          border-radius: 5px;
          background-color: #428bca;
          position: fixed;
          right: 0;
          bottom: 0;
          z-index: 9999;
          color: white;
      }
      .contact-us-btn:hover{
          cursor: pointer;
          background: rgb(241, 174, 50);
          color: #428bca;
      }
      .terms-conditions{
          background-color: #fff;
          min-height: 100vh;  
          margin: 8rem 15rem       
      }
      p{
          font-weight: 100;
          font-size: 0.85rem;
      }
      .tabbed-paragraphs{
          margin-left: 3rem;
      }
      h5{
          font-weight: 300;
      }
         /* media queries*/ 
/* Extra small devices (phones, 600px and down) */
@media screen and (max-width: 600px) {
  .terms-condition-container{
    overflow-x:hidden;
    width: 100vw;
  }
  .home-wrapper{
    background-color:#fff;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 90vh;
    margin-top: -12rem;
  }
  .more-home-content{
    left: -.1rem;
    top:16rem;
    height: 6rem;
    margin-right: -1rem;
  }
  .more-home-content p{
        font-size: 1rem;
    }
  .terms-conditions{
    width: 20rem;
    margin: -6rem .5rem 2rem .5rem;
  }
}
</style>